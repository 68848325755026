/* FILTER MODAL */
.filters-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.filters-container .title {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color);
  margin: 0px;
}
.filters-container .info {
  font-size: 14px;
  font-weight: 400;
  color: var(--gray);
  margin: 0px;
}
.filters-container .option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border: 1px solid var(--gray-300);
  margin-bottom: 10px;
  border-radius: 6px;
  padding: 0px 10px;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* estándar */

  cursor: pointer;

  height: 32px;
  width: 100%;
}
.filters-container .option:hover {
  background-color: var(--gray-100);
}
.filters-container .option.active {
  background-color: var(--light-blue);
}
.filters-container .option.active:hover {
  background-color: var(--light-blue);
}
.filters-container .option-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.filters-container .option-row .info {
  margin-right: 5px;
}
.filters-container .option-row .anticon {
  font-size: 12px;
  color: var(--secondary-color);
  margin-right: 2.5px;
}
/* END FILTER MODAL */
.ratings-by-workshop {
  display: flex;
  flex-direction: column;
  padding: 30px 5%;
  background: #fff;
}
.ratings-by-workshop > h1 {
  color: #444;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  /* padding-bottom: 20px;
  width: 100%;
  border-bottom: 1px dashed #ccc; */
}
.ratings-by-workshop .text-bold {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color);
}
.ratings-by-workshop .text-regular,
.ratings-by-workshop .text-regular span {
  font-size: 16px;
  font-weight: 400;
  color: var(--text-color);
}
.ratings-by-workshop .primary-color,
.ratings-by-workshop .primary-color span {
  color: var(--primary-color);
}
.ratings-by-workshop .no-margin {
  margin: 0px;
}
.ratings-by-workshop .s14 {
  font-size: 14px;
}
.ratings-by-workshop .s12 {
  font-size: 12px;
}
.ratings-by-workshop .transform-180 {
  transform: rotateX(180deg) !important;
}
.ratings-by-workshop .message-text {
  font-size: 16px;
  font-weight: 400;
  color: var(--text-color);
  padding: 10px;
  background-color: #f9f9f9;
}
.ratings-by-workshop .col-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}
.ratings-by-workshop .row-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.ratings-by-workshop .flex-wrap {
  flex-wrap: wrap;
}
.ratings-by-workshop .space-between {
  justify-content: space-between;
}
.ratings-by-workshop .rate-count-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.ratings-by-workshop .rate-count-container .rate-count {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.ratings-by-workshop .rate-count-container .rate-count .num {
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
  color: black;
  text-align: left;
}
.ratings-by-workshop .rate-count-container .rate-count .anticon {
  font-size: 12px;
  color: var(--secondary-color);
  /* margin-right: 2.5px; */
}
.ratings-by-workshop
  .rate-count-container
  .rate-count
  .ant-progress
  .ant-progress-bg {
  height: 6px !important;
  background-color: var(--primary-color);
}
.ratings-by-workshop .rate-count-container .rate-count .info {
  font-size: 12px;
  font-weight: 400;
  color: black;
  margin: 0px;
  text-align: right;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;
  min-width: 100px;
}
.ratings-by-workshop .tags-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.ratings-by-workshop .tag-text {
  font-weight: 600 !important;
  color: var(--text-color);
  padding: 4.5px 12px;
  border-radius: 6px;
  background-color: var(--light-blue);
}
.ratings-by-workshop .tag-text-gray {
  font-weight: 400 !important;
  color: var(--text-color);
  padding: 4.5px 12px;
  border-radius: 6px;
  background-color: var(--gray-200);
  margin: 0px;
}
.ratings-by-workshop .info-gray {
  border: none;
  border-radius: 8px;
  background-color: #f9f9f9;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}
.ratings-by-workshop .info-gray .anticon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.ratings-by-workshop .info-gray .anticon svg {
  color: var(--mariner-color);
}
.ratings-by-workshop .info-gray .ant-alert-description {
  font-size: 12px;
  color: var(--gray);
}
.ratings-by-workshop .btn-sort {
  width: 83px !important;
  padding: 0px !important;
}
.ratings-by-workshop .order-icons,
.ratings-by-workshop .menu-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;
}
.ratings-by-workshop .menu-icons {
  border: 1px solid var(--primary-color);
  border-radius: 50px;
}
.ratings-by-workshop .order-icons .anticon,
.ratings-by-workshop .menu-icons .anticon {
  width: 10px;
  height: 10px;
  color: var(--primary-color) !important;
}
.ratings-by-workshop .menu-icons .anticon {
  margin-bottom: 5px;
}

.report-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 20px;
  padding-bottom: 20px;
}
.report-modal-container .ant-btn {
  border-width: 2px;
}
.report-modal-container .ant-btn span {
  color: var(--text-color) !important;
}
.report-modal-container .ant-btn.active {
  background-color: var(--primary-color) !important;
}
.report-modal-container .ant-btn.active span {
  color: white !important;
}

/*=============================================
=            Pagination            =
=============================================*/
.ratings-by-workshop .ant-pagination {
  font-size: 16px !important;
  color: #444;
  margin: 20px auto;
  text-align: center;
}
.ratings-by-workshop
  .ant-pagination
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 10px !important;
  border-color: #444;
}
.ratings-by-workshop
  .ant-pagination
  .ant-pagination-options-size-changer.ant-select {
  font-size: 16px !important;
}
.ratings-by-workshop .ant-pagination-total-text {
  font-weight: 600;
}
.ratings-by-workshop .ant-pagination button,
.ratings-by-workshop .ant-pagination .ant-pagination-item {
  border: 1px solid #444 !important;
}
.ratings-by-workshop
  .ant-pagination
  .ant-pagination-item.ant-pagination-item-active {
  border-color: var(--primary-color) !important;
}

.ratings-by-workshop .ant-pagination .ant-pagination-item-active a {
  color: var(--primary-color);
  font-weight: 600;
}

.ratings-by-workshop .flex-rate-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 60px;
  padding-bottom: 20px;
}
.ratings-by-workshop .flex-rate-container .rate-count-container {
  max-width: 317px;
}
.ratings-by-workshop .rate-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.order-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.order-content .ant-btn-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-height: 28px !important;
  min-height: 28px !important;
  padding: 10px !important;
}
.order-content .ant-btn-text span {
  font-size: 14px !important;
  font-weight: 400;
  text-align: start !important;
}
.order-content .ant-btn-default {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-height: 28px !important;
  min-height: 28px !important;
  padding: 10px !important;
}
.order-content .ant-btn-default span {
  font-size: 14px !important;
  font-weight: 600 !important;
  text-align: start !important;
}

@media (min-width: 769px) {
  .ratings-by-workshop .tags-container {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px 40px;
  }
  .ratings-by-workshop .tags-container .bold-primary {
    min-width: 100% !important;
  }
  .ratings-by-workshop .tags-container .row-item p {
    margin: 0px;
  }
}
@media (max-width: 768px) {
  .ratings-by-workshop .flex-rate-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding-bottom: 20px;
    width: 100%;
  }
  .ratings-by-workshop .flex-rate-container .rate-count-container {
    width: 100% !important;
    max-width: 100%;
  }
  .ratings-by-workshop .ant-pagination {
    margin: 0 auto;
  }
  .ratings-by-workshop .ant-pagination-total-text {
    display: block;
    margin: 10px auto 5px auto;
  }
  .ratings-by-workshop .ant-pagination-options {
    display: block;
    margin: 15px 0 0 0;
  }
}

/*=====  End of Pagination  ======*/

/*=============================================
=                  Rate card                  =
=============================================*/
.rate-card {
  width: 100%;
  padding: 20px 0;
  border-top: 1px dashed #ccc;
  color: #444;
}
.rate-card.reported {
  padding: 10px;
  background-color: rgba(var(--red-dark-rgb), 0.06);
}
.ratings-by-workshop .rate-card:last-child {
  border-bottom: 1px dashed #ccc;
}
.rate-card p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
}

/*----------  Subsection identification  ----------*/
.rate-card .identification {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .rate-card .identification {
    flex-direction: column;
    gap: 5px;
  }
}
.rate-card .identification p {
  line-height: 25px;
}
.rate-card .identification .date {
  color: var(--primary-color);
  font-weight: 600;
}
.rate-card .identification .date > span {
  text-transform: capitalize;
  /* font-weight: 400; */
}
.rate-card .identification .rate-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 215px;
  min-width: 215px;
}
.rate-card .identification .rate-wrapper p {
  line-height: 25px;
}

/*----------  Subsection comment container  ----------*/
.rate-card .comment-container {
  margin-bottom: 10px;
}
.rate-card .comment-container .comment {
  white-space: pre-line;
  word-break: break-all;
  overflow-wrap: break-word;
}
.rate-card .comment-container .comment-date {
  font-weight: 600;
}
.rate-card .comment-container .comment-date > span {
  text-transform: capitalize;
}

.rate-card .comment-container .notice {
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  color: #fff;
  background: #666;
  width: auto;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 15px;
  margin-top: 5px;
}

/*----------  Action buttons  ----------*/
.rate-card .actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  position: relative;
  /* top: 0; */
  /* left: 0; */
  /* height: 35px; */
  overflow: hidden;
}
.rate-card .actions .anticon {
  height: 20px;
  width: 20px;
  margin-bottom: -5px;
}
.rate-card .actions .anticon svg {
  max-width: 20px;
  max-height: 20px;
}
.rate-card .actions.hide {
  height: 0;
}
.rate-card .actions .ant-btn {
  padding: 0;
  height: 30px;
}
.rate-card .actions .ant-btn > span {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
}
.rate-card .actions .ant-btn span.anticon {
  transition: all 0.5s ease;
  transform: translateY(-3px);
}
.rate-card .actions .ant-btn.expanded span.anticon {
  transform: translateY(-3px) rotate(180deg);
}
.rate-card .actions .expand-thread {
  position: absolute;
  top: 0;
  left: 0;
}
.rate-card .actions .expand-submit {
  position: absolute;
  top: 0;
  right: 0;
}

/*----------  conversation-thread  ----------*/
.rate-card .conversation-thread {
  width: 100%;
  height: 0;
  overflow: hidden;
}
.rate-card .conversation-thread.expanded {
  height: 100%;
  padding-top: 10px;
}
.rate-card .conversation-thread .thread-comment {
  border-left: 2px solid var(--primary-color);
  margin-left: 20px;
  padding-left: 10px;
}
.rate-card .conversation-thread .thread-comment .comment {
  white-space: pre-line;
  word-break: break-all;
  overflow-wrap: break-word;
}
.rate-card .conversation-thread .thread-comment p {
  font-size: 16px;
  line-height: 20px;
  color: #444;
}
.rate-card .conversation-thread .thread-comment p:first-child {
  font-weight: 600;
}
.rate-card .conversation-thread .thread-comment .notice {
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  color: #fff;
  background: #666;
  width: auto;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 15px;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .rate-card .conversation-thread .thread-comment {
    margin-left: 0;
  }
}

/*----------  submit-message  ----------*/
.rate-card .submit-message {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 0;
  overflow: hidden;
}
.rate-card .submit-message.expanded {
  height: 100%;
}
.rate-card .submit-message form {
  padding: 0 !important;
  width: 100%;
}
.rate-card .submit-message .submit-options {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  margin-top: 10px;
}
.rate-card .submit-message .submit-options .ant-btn {
  padding-left: 0;
  padding-right: 0;
}
.rate-card .submit-message .submit-options .ant-btn span {
  font-size: 16px !important;
  font-weight: 600;
}
.rate-card .submit-message .submit-options .ant-btn:last-child {
  width: 200px;
}
.rate-card
  .submit-message
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}
.rate-card .submit-message label {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 600;
}

.rate-card .submit-message textarea {
  border: 1px solid #ccc;
  background: #fff;
}

.rate-card .submit-message .ant-form-item-explain > div {
  color: #666;
  font-size: 12px;
  margin-top: 5px;
}

@media (min-width: 768px) {
  .rate-card .submit-message {
    max-width: 450px;
  }
  .rate-card .submit-message .submit-options {
    justify-content: flex-end;
  }
}

/*=====  End of Rate card  ======*/

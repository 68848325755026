.profile-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  /* height: 92.5vh; */
  min-height: 92.5vh;
  background-color: white;
  padding: 20px 60px !important;
}
.profile-container .for-web {
  display: block !important;
}
.profile-container .for-mob {
  display: none !important;
}
.profile-container .photo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 992px) {
  .profile-container .photo-container {
    margin-bottom: 20px !important;
  }
}
.profile-container .photo-container .ws-photo {
  width: 180px !important;
  height: 180px !important;
  object-fit: cover !important;
  border-radius: 6px !important;
}
.profile-container .photo-container .profile-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 180px !important;
  margin-top: 3px !important;
}
.profile-container .photo-container .btn-photo {
  width: 100% !important;
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  padding: 0px !important;
}
.profile-container .photo-container .btn-photo span {
  font-size: 16px;
  line-height: 20px;
  color: var(--primary-color);
  font-family: "Emprint-semibold";
}
.profile-container .profile-info {
  width: 100% !important;
  padding: 0px 0px 0px 20px !important;
}
.profile-container .profile-info .ant-form {
  padding: 0px !important;
}

/* Form containers style */
.profile-container .profile-info .row-info {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.profile-container .profile-info .col-info-left {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 40px;
}
.profile-container .profile-info .col-info-right {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 40px;
}
.profile-container .profile-info .password-options {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.profile-container .profile-info .options-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 40px !important;
}
/* Form containers style */

/* Form item style */
.profile-container .profile-info .ant-form-item {
  width: 100% !important;
}
.profile-container .profile-info .ant-form-item .ant-form-item-row {
  display: flex !important;
  flex-direction: column !important;
  justify-content: start !important;
  align-items: start !important;
  width: 100% !important;
}
.profile-container
  .profile-info
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-control {
  text-align: start !important;
  width: 100% !important;
}
.profile-container
  .profile-info
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-control
  .ant-form-item-explain-success {
  font-size: 12px !important;
  line-height: 15px !important;
  color: #2da661 !important;
}
.profile-container
  .profile-info
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-control
  .ant-form-item-explain-connected
  div {
  font-size: 12px !important;
  line-height: 15px !important;
}
.profile-container
  .profile-info
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-control
  .ant-form-item-explain-error {
  font-size: 12px !important;
  line-height: 15px !important;
}
.profile-container
  .profile-info
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-control
  .ant-picker {
  text-align: start !important;
  width: 100% !important;
}
.profile-container
  .profile-info
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-control
  .ant-input-number {
  text-align: start !important;
  width: 100% !important;
}
/* Form item style */

/* Form item font style */
.profile-container
  .profile-info
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-label
  label {
  color: #444444 !important;
  font-size: 16px;
  line-height: 20px;
  font-family: "Emprint-semibold";
}
.profile-container
  .profile-info
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-label
  label::before {
  color: #444444 !important;
  font-size: 16px;
  line-height: 20px;
  font-family: "Emprint-semibold";
  margin: 0px !important;
}
.profile-container
  .profile-info
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-label
  label::after {
  display: none !important;
}
.profile-container
  .profile-info
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-control-input-content
  input {
  color: #444444 !important;
  font-size: 16px;
  line-height: 20px;
  font-family: "Emprint-regular";
  padding-bottom: 5px !important;
}
.profile-container
  .profile-info
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-control-input-content
  input::placeholder {
  font-size: 16px;
  line-height: 20px;
  font-family: "Emprint-regular";
  padding-bottom: 5px !important;
}
/* Form item font style */

/* Inputs style */
.profile-container .ant-input {
  border: none !important;
  border-bottom: 1px solid #444444 !important;
  border-radius: 0px !important;
  padding: 0px !important;
}
.profile-container .ant-input-number {
  border: none !important;
  border-radius: 0px !important;
  padding: 0px !important;
}
.profile-container .ant-input-number-input {
  border: none !important;
  border-bottom: 1px solid #444444 !important;
  border-radius: 0px !important;
  padding: 0px !important;
}
.profile-container .ant-input-affix-wrapper {
  border: none !important;
  border-radius: 0px !important;
  padding: 0px !important;
}
.profile-container .ant-input-suffix {
  border: none !important;
  border-bottom: 1px solid #444444 !important;
  border-radius: 0px !important;
  padding: 0px 0px 0px 5px !important;
  margin: 0px !important;
}
.profile-container .ant-picker {
  border: none !important;
  border-bottom: 1px solid #444444 !important;
  border-radius: 0px !important;
  padding: 0px !important;
}
.profile-container .ant-picker .ant-picker-suffix {
  color: #444444 !important;
}
.profile-container .ant-picker .ant-picker-clear {
  color: #444444 !important;
}
.profile-container .ant-radio .ant-radio-inner {
  border: 2px solid #2f66f2 !important;
}
/* Inputs style */

/* Buttons style */
.profile-container .profile-info .password-options .ant-btn-link {
  padding: 0px !important;
}
.profile-container .profile-info .password-options .ant-btn-link span {
  font-size: 16px !important;
  line-height: 20px !important;
  font-family: "Emprint-semibold";
  color: var(--primary-color);
  text-decoration: underline;
}
/* Buttons style */
@media only screen and (max-width: 1100px) {
  .profile-container {
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    /* height: 92.5vh; */
    min-height: 92.5vh;
    background-color: white;
    padding: 20px 5% !important;
  }
  .profile-container .for-web {
    display: none !important;
  }
  .profile-container .for-mob {
    display: block !important;
  }
  .profile-container .profile-info {
    padding: 0px !important;
  }
  .profile-container .photo-container .ws-photo {
    width: 240px !important;
    height: 240px !important;
  }
  /* Form containers style */
  .profile-container .profile-info .row-info {
    /* flex-direction: column; */
    gap: 0px;
  }
  .profile-container .profile-info .col-info-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .profile-container .profile-info .col-info-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .profile-container .profile-info .options-container {
    flex-direction: column-reverse;
    justify-content: center;
    gap: 20px !important;
  }
  .profile-container .profile-info .options-container .delete-data-btn-container {
    display: flex;
    flex-direction: column;
    align-content: center;
  }
  a,
  p,
  span.standar,
  .ant-select-item-option,
  .ant-select-selection-item,
  .ant-table-content,
  .ant-form-item-extra,
  .ant-form label,
  .table-regular {
    font-size: 16px;
  }
  /* Form containers style */
}

.delete-account-confirmation-modal .ant-modal-body {
  text-align: center;
}
.delete-account-confirmation-modal .ant-modal-body p {
  color: #444 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  margin-bottom: 10px;
}
.delete-account-confirmation-modal .ant-modal-body p span {
  font-weight: 600;
}
.delete-account-confirmation-modal .ant-modal-body input.ant-input {
  margin: 10px auto 15px auto;
  width: 240px;
  text-align: center;
  text-transform: uppercase;
}
.delete-account-confirmation-modal .ant-modal-body textarea.ant-input {
  width: 100%;
  border-radius: 10px !important;
}
.delete-account-confirmation-modal .ant-modal-body .ant-form {
  padding: 0 !important;
  width: 100%;
  margin: 0px auto 20px auto;
}
.delete-account-confirmation-modal .ant-modal-body .ant-checkbox-wrapper {
  width: 100%;
  text-align: left;
}
.delete-account-confirmation-modal .ant-modal-body .buttons-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px auto;
}
.delete-account-confirmation-modal .ant-modal-body .ant-btn {
  width: 240px;
}
.delete-account-confirmation-modal .ant-modal-body .ant-btn > span {
  font-weight: 600;
}
.delete-account-confirmation-modal .ant-modal-body h6 {
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 600;
  margin-bottom: 10px;
  color: #444;
}
.delete-account-confirmation-modal .ant-modal-body a {
  color: #454545;
  margin: 0 auto 10px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.delete-account-confirmation-modal .reasons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.delete-account-confirmation-modal .img-icon-double {
  color: var(--secondary-dark-color);
}
.delete-account-confirmation-modal .help-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-bottom: 20px;
}
.delete-account-confirmation-modal .help-container p,
.delete-account-confirmation-modal .help-container a {
  font-size: 14px !important;
  color: #444444 !important;
  margin: 0px !important;
}
.delete-account-confirmation-modal .help-container .v-p,
.delete-account-confirmation-modal .help-container .v-w {
  font-size: 14px !important;
  margin: 0px 2.5px 0px 0px !important;
  color: #444444 !important;
  width: 16px !important;
  height: 16px !important;
}

.data-retention-link-btn-txt {
  font-weight: 600;
  text-decoration: underline;
  font-size: 14px !important;
  color: #001450;
}

.data-retention-link-btn-txt:hover {
  text-decoration: underline;
}

/* style={{
  color: "#454545",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
}} */

/* color: "#a8a8a8", */
